<template>
    <div class="enterpriseSubsidy_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.whyProfessionalAgencyName"> </CourseTitle>
            <div class="content agencyAgent">
                <div class="agencyAgent_div">
                    <ul class="Between flex-wrap">
                        <li class="flex-center" :class="index % 2 != 0 ? 'list_div' : ''" v-for="(
								item, index
							) in formData.enterpriseSubsidyWhyProfessionalAgencys" :key="index">
                            {{ item.agencyName }}
                        </li>
                        <div class="vsImg_div">
                            <img :src="formData.whyProfessionalAgencyImgUrl" alt="" />
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.golgaAdvantageName"> </CourseTitle>
            <div class="content gejAdvantage">
                <ul class="display">
                    <li class="flex column" v-for="(item, index) in formData.enterpriseSubsidyGolgaAdvantages" :key="index">
                        <div class="title display">{{ item.golgaAdvantageName }}</div>
                        <div class="main">
                            <div class="list display" v-for="(items, indexs) in item.enterpriseSubsidyGolgaAdvantages"
                                :key="indexs">
                                {{ items.golgaAdvantageName }}
                            </div>
                        </div>
                        <div class="consultationBtn display pointer" @click="service">
                            立即咨询+
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.trainingSubsidyName" :contText="formData.trainingSubsidyIntroduce">
            </CourseTitle>
            <div class="content trainingSubsidy">
                <img class="icon_line" src="./img/enterpriseSubsidy/line.png" alt="" />
                <ul class="Around">
                    <li class="display column" v-for="(item, index) in formData.enterpriseSubsidyTrainingSubsidys"
                        :key="index">
                        <div class="text">
                            {{ item.trainingSubsidyName }}
                        </div>
                        <div class="name">{{ item.trainingSubsidyIntroduce }}</div>
                    </li>
                </ul>
            </div>
            <div class="btn display pointer" @click="service">立即咨询</div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.workAllowanceName" :contText="formData.workAllowanceIntroduce">
            </CourseTitle>
            <div class="content workAllowance">
                <div class="subsidyTerms_div">
                    <div class="title">补贴条件：</div>
                    <ul>
                        <li class="subsidyTerms_li flex" v-for="(item, index) in formData.enterpriseSubsidyWorkAllowances"
                            :key="index">
                            <div class="num display">{{ index + 1 }}.</div>
                            <div class="text">
                                {{ item.workAllowanceName }}
                            </div>
                        </li>
                    </ul>
                    <img class="icon_fm" :src="formData.workAllowanceImgUrl" alt="" />
                </div>
            </div>
            <div class="btn display pointer" @click="service">立即咨询</div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.evaluationSubsidyName" :contText="formData.evaluationSubsidyIntroduce"
                :isWhite="1">
            </CourseTitle>
            <div class="content enterpriseEvaluationSubsidy flex">
                <div class="fl">
                    <div class="title">企业评价补贴申请条件：</div>
                    <ul>
                        <li class="flex-center" v-for="(
								item, index
							) in formData.enterpriseSubsidyEvaluationSubsidys" :key="index">
                            <img class="icon_img" :src="item.evaluationSubsidyImgUrl" alt="" />
                            <div class="text">{{ item.evaluationSubsidyName }}</div>
                        </li>
                    </ul>
                </div>
                <div class="fr">
                    <img :src="formData.evaluationSubsidyImgUrl" alt="" />
                </div>
            </div>
            <div class="btn display pointer" @click="service">立即咨询</div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.taxRebateSubsidyName" :contText="formData.taxRebateSubsidyIntroduce">
            </CourseTitle>
            <div class="content rebateSubsidy">
                <Module7S :rebateSubsidy="formData.enterpriseSubsidyTaxRebateSubsidys"></Module7S>
            </div>
            <div class="btn display pointer" @click="service">退税计算器</div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.serviceProcessName"> </CourseTitle>
            <div class="content notificationProcedures">
                <ul class="notificationProcedures_ul display">
                    <li class="notificationProcedures_li display column"
                        v-for="(item, index) in formData.enterpriseSubsidyServiceProcessS" :key="index">
                        <img :src="item.serviceProcessImgUrl" alt="" />
                        <div class="name nowrap">{{ item.serviceProcessName }}</div>
                        <img class="icon_jt" src="./img/highTechEnterprise/jiantou.png" alt="" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.whatSubsidiesReceiveName"> </CourseTitle>
            <FreeCharge :status="14" :type="'企业补贴申报'"></FreeCharge>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
import Module7S from '../components/enterpriseService/module7'
import FreeCharge from '@/components/freeCharge'
export default {
    components: {
        BannerImg,
        CourseTitle,
        Module7S,
        FreeCharge,
    },
    data() {
        return {
            list: [{}, {}, {}, {}],
            formData: {},
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.enterpriseSubsidyApi().then((res) => {
                this.formData = res.data
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.enterpriseSubsidy_page {
    .btn {
        width: 428px;
        height: 48px;
        background: #ff881e;
        border-radius: 5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
    }

    .module1 {
        height: 590px;
        background: url('./img/enterpriseSubsidy/bg1.png') no-repeat;
        background-size: 100% 100%;

        .agencyAgent {
            margin-top: 41px;

            ul {
                position: relative;
            }

            .list_div {
                background: url('./img/enterpriseSubsidy/bg3.png') no-repeat !important;
                color: #fff !important;
            }

            .agencyAgent_div {
                li {
                    width: 390px;
                    height: 60px;
                    background: url('./img/enterpriseSubsidy/bg2.png') no-repeat;
                    background-size: 100% 100%;
                    padding-left: 51px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-bottom: 30px;
                }
            }

            .vsImg_div {
                width: 212px;
                height: 244px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -122px;
                margin-left: -106px;
            }
        }
    }

    .module2 {
        height: 590px;

        .gejAdvantage {
            margin-top: 56px;

            ul {
                li {
                    width: 250px;
                    height: 304px;
                    background: url('./img/enterpriseSubsidy/bg4.png') no-repeat;
                    background-size: 100% 100%;
                    position: relative;
                    margin: 0 32px;

                    .title {
                        width: 250px;
                        height: 45px;
                        font-size: 30px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        margin-top: 15px;
                    }

                    .main {
                        margin: 0 auto;

                        .list :last-child {
                            border-bottom: none !important;
                        }

                        .list {
                            width: 214px;
                            height: 64px;
                            border-bottom: 1px solid #dddddd;
                        }
                    }

                    .consultationBtn {
                        width: 250px;
                        height: 44px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }

    .module3 {
        height: 600px;
        background: url('./img/enterpriseSubsidy/bg7.png') no-repeat;
        background-size: 100% 100%;

        .trainingSubsidy {
            margin-top: 56px;

            .icon_line {
                width: 1200px;
                height: 18px;
            }

            ul {
                margin-top: 29px;

                li {
                    width: 248px;

                    .text {
                        height: 92px;
                        width: 234px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        text-align: center;
                    }

                    .name {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #054d9d;
                        margin-top: 15px;
                    }
                }
            }
        }

        .btn {
            margin-top: 35px;
        }
    }

    .module4 {
        height: 920px;

        .workAllowance {
            margin-top: 55px;

            .subsidyTerms_div {
                width: 848px;
                height: 490px;
                border: 1px solid #054d9d;
                padding-top: 58px;
                padding-left: 116px;
                margin-left: 352px;
                position: relative;

                .icon_fm {
                    width: 430px;
                    height: 380px;
                    position: absolute;
                    top: 55px;
                    left: -352px;
                }

                .title {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 20px;
                    margin-bottom: 22px;
                }

                ul {
                    .subsidyTerms_li {
                        margin-bottom: 20px;

                        .num {
                            width: 28px;
                            height: 22px;
                            background: #054d9d;
                            border-radius: 5px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            margin-right: 24px;
                        }

                        .text {
                            width: 654px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: -4px;
                        }
                    }
                }
            }
        }

        .btn {
            margin-top: 58px;
        }
    }

    .module5 {
        height: 800px;
        background: url('./img/enterpriseSubsidy/bg6.png') no-repeat;
        background-size: 100% 100%;

        .enterpriseEvaluationSubsidy {
            margin-top: 66px;

            .fl {
                padding-top: 10px;

                .title {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 20px;
                    margin-bottom: 20px;
                }

                ul {
                    li:last-child {
                        border-bottom: none !important;
                    }

                    li {
                        width: 385px;
                        height: 96px;
                        border-bottom: 1px solid #dddddd;
                        padding-left: 25px;

                        .icon_img {
                            width: 48px;
                            height: 48px;
                        }

                        .text {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            margin-left: 22px;
                        }
                    }
                }
            }

            .fr {
                margin-left: 70px;

                img {
                    width: 734px;
                    height: 356px;
                }
            }
        }

        .btn {
            margin-top: 55px;
        }
    }

    .module6 {
        height: 720px;

        .rebateSubsidy {
            margin-top: 18px;
        }

        .btn {
            margin-top: 75px;
        }
    }

    .module7 {
        height: 450px;
        background: url('./img/enterpriseSubsidy/bg5.png') no-repeat;
        background-size: 100% 100%;

        .notificationProcedures {
            margin-top: 70px;

            .notificationProcedures_ul {
                .notificationProcedures_li:nth-child(1) {
                    margin-left: 0 !important;
                }

                .notificationProcedures_li:last-child {
                    .icon_jt {
                        display: none;
                    }
                }

                .notificationProcedures_li {
                    margin-left: 108px;
                    position: relative;

                    .icon_jt {
                        width: 32px;
                        height: 18px;
                        position: absolute;
                        top: 41px;
                        right: -68px;
                    }

                    img {
                        width: 96px;
                        height: 96px;
                    }

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333;
                        line-height: 24px;
                        margin-top: 28px;
                        text-align: center;
                    }
                }
            }
        }
    }
}</style>